@import '../../mixins';

.dropdown {
  width: 100%;
  display: flex;
  flex-direction: column;

  &_light {
    .dropdown {
      &__current {
        border-bottom: solid 1px var(--stroke-light);

        @include mediaBigDesktop {
          border-bottom-width: big(1);
        }
      }
    }
  }

  &__item {
    &:first-child {
      & .dropdown {
        &__current {
          padding-top: 0;
        }
      }
    }
  }

  &__current {
    position: relative;
    padding: rem(20) 0;

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: rem(20);

    border-bottom: solid 1px var(--stroke-dark);

    transition: border-color var(--animation-timing) var(--cubic-bezier);

    cursor: pointer;

    @include mediaBigDesktop {
      padding: big(20) 0;

      gap: big(20);

      border-bottom-width: big(1);
    }

    @include hover {
      border-color: var(--accent-color);

      & .h3 {
        color: var(--accent-color);
      }
    }

    & .h3 {
      color: var(--section-text-color-primary);

      transition: color var(--animation-timing) var(--cubic-bezier);
    }

    & .button {
      flex-shrink: 0;

      &::before {
        display: none;
      }
    }
  }

  &__content {
    padding: 0;

    width: 100%;
    height: 0;
    max-width: var(--article-width);
    display: block;
    box-sizing: content-box;

    overflow: hidden;

    transition: height var(--animation-timing) var(--cubic-bezier),
      padding var(--animation-timing) var(--cubic-bezier);

    & .article {
      margin-left: 0;
    }

    & .text {
      color: var(--section-text-color-secondary);
    }
  }

  &__checkbox {
    &:checked {
      & ~ .dropdown {
        &__current {
          border-color: var(--accent-color);

          & .h3 {
            color: var(--accent-color);
          }

          & .button {
            &__icon {
              fill: var(--accent-color);
              transform: scale(1, -1);
            }
          }
        }

        &__content {
          padding: rem(20) 0;

          @include mediaBigDesktop {
            padding: big(20) 0;
          }
        }
      }
    }
  }
}
